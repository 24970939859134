<template>
    <form @submit.prevent="updateProvider">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mb-4">
                    <h3 class="m-0">{{ PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.Title }}</h3>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <recess-input :value="provider.name" :label-text="PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.Name.Label" :disabled="true" class="qa-provider-name" />
                </div>
                <div class="col-6">
                    <recess-textarea-input
                        :value="provider.introduction"
                        rows="3"
                        :label-text="PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.Introduction.Label"
                        :placeholder-text="PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.Introduction.Placeholder"
                        class="qa-provider-introduction"
                        @input="(newValue) => setProviderIntroduction(newValue)"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <recess-input
                        :value="provider.websiteUrl"
                        :label-text="PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.WebsiteUrl.Label"
                        class="qa-website-url"
                        :placeholder-text="PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.WebsiteUrl.Placeholder"
                        @input="(newValue) => setWebsiteUrl(newValue)"
                    />
                </div>
                <div class="col-6">
                    <recess-input
                        :value="provider.termsAndConditionsUrl"
                        :label-text="PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.TermsAndConditionsUrl.Label"
                        class="qa-terms-and-condition-url"
                        :placeholder-text="PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.WebsiteUrl.Placeholder"
                        @input="(newValue) => setTermsAndConditionsUrl(newValue)"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <recess-file-upload
                        :label-text="PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.Logo.Label"
                        :placeholder-text="PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.Logo.Placeholder"
                        :max-size-file="PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.Logo.MaxSizeFile"
                        :max-file-to-upload="PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.Logo.MaxNumberFileToUpload"
                        :allowed-extensions="['png']"
                        class="qa-provider-logo"
                        ref="uploadFile"
                        @upload="(newValue) => triggerChangeLogo(newValue)"
                    />

                    <div v-if="provider.logoUrl" class="mt-4 c-header">
                        <span class="u-fw-semi-bold">{{ PROVIDER.ProviderSettingsPage.Settings.GeneralInfo.Logo.ShowPreviewMessage }}</span>
                        <div class="c-header__left">
                            <img :src="userLogo" alt="provider-logo" class="c-header__logo mt-3 qa-provider-logo-url" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h3 class="my-4">
                        {{ PROVIDER.ProviderSettingsPage.Settings.Edudex.Title }}
                    </h3>
                    <recess-input
                        :value="provider.importUrl"
                        :label-text="PROVIDER.ProviderSettingsPage.Settings.Edudex.ImportUrl.Label"
                        :disabled="true"
                        class="qa-provider-importUrl"
                        @input="(newValue) => setImportUrl(newValue)"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-8">
                    <h3 class="my-4">
                        {{ PROVIDER.ProviderSettingsPage.Settings.ProviderInfo.Title }}
                    </h3>
                    <recess-checkbox-input
                        :value="provider.isVatExempt"
                        :default-checked="provider.isVatExempt"
                        :label-text="PROVIDER.ProviderSettingsPage.Settings.ProviderInfo.IsVatExempt.Label"
                        variant="secondary"
                        name="is-vat-exempt"
                        class="_box-sizing--content-box mb-2 qa-provider-is-vat-exempt"
                        @input="(newValue) => setProviderIsVatExempt(newValue)"
                    />

                    <h6>
                        {{ PROVIDER.ProviderSettingsPage.Settings.ProviderInfo.IsVatWarning }}
                    </h6>
                </div>
            </div>

            <div class="row">
                <div v-if="originalProvider.providerOleTypes && originalProvider.providerOleTypes.length > 0" class="col-12">
                    <h3 class="my-4">{{ PROVIDER.ProviderSettingsPage.Settings.LMSSettings.Title }}</h3>

                    <recess-table-flex class="qa-table-provider-settings-list" :table-options="setTableOptions" :pagination-options="setPaginationOptions">
                        <recess-table-flex-head>
                            <recess-table-flex-row>
                                <recess-table-flex-head-item v-for="(th, index) in thead" :key="index" :class="th.className" :cell-size="th.cellSize">
                                    {{ th.title }}
                                </recess-table-flex-head-item>
                            </recess-table-flex-row>
                        </recess-table-flex-head>
                        <recess-table-flex-body>
                            <recess-table-flex-row v-for="(tr, indextr) in provider.providerOleTypes" :key="indextr">
                                <recess-table-flex-cell :cell-size="thead[0].cellSize">
                                    {{ provider.providerOleTypes[indextr].oleType }}
                                </recess-table-flex-cell>
                                <recess-table-flex-cell :cell-size="thead[1].cellSize">
                                    <recess-input
                                        :value="provider.providerOleTypes[indextr].baseUrl"
                                        class="qa-provider-ole-type-baseUrl-input"
                                        @input="(newValue) => setOleTypeBaseUrl({ indextr, baseUrl: newValue })"
                                    />
                                </recess-table-flex-cell>
                                <recess-table-flex-cell :cell-size="thead[2].cellSize">
                                    <recess-checkbox-input
                                        :default-checked="provider.providerOleTypes[indextr].hasSso"
                                        :value="provider.providerOleTypes[indextr].hasSso"
                                        class="qa-provider-ole-type-checkbox"
                                        @input="(newValue) => setOleTypeHasSso({ indextr, hasSso: newValue })"
                                    />
                                </recess-table-flex-cell>
                            </recess-table-flex-row>
                        </recess-table-flex-body>
                    </recess-table-flex>
                </div>
                <div class="col-12 text-right mt-4">
                    <recess-button :title="BUTTON_TEXT.save" type="submit" variant="secondary" class="qa-edit-provider-button" />
                </div>
            </div>
        </div>
    </form>
</template>
 
<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import { BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

export default {
    name: 'ProviderSettingsGeneralInfoTab',
    data() {
        return {
            BUTTON_TEXT,
            PROVIDER,
            previewSelectedLogo: null,
            thead: [
                {
                    title: PROVIDER.ProviderSettingsPage.Settings.LMSSettings.TableHeaders.LMS,
                    cellSize: 'medium'
                },
                {
                    title: PROVIDER.ProviderSettingsPage.Settings.LMSSettings.TableHeaders.Url,
                    cellSize: 'large'
                },
                {
                    title: PROVIDER.ProviderSettingsPage.Settings.LMSSettings.TableHeaders.SSOActive,
                    cellSize: 'small'
                }
            ],
            // TABLE AND PAGINATION OPTIONS - REQUIRED
            setTableOptions: {
                setCellSizes: true
            },
            setPaginationOptions: {
                showTopPagination: false,
                showBottomPagination: false
            }
        }
    },
    computed: {
        ...mapState('providerModule', ['provider', 'originalProvider']),
        userLogo() {
            if (this.provider.logoUrl && !this.previewSelectedLogo) {
                return this.provider.logoUrl
            }

            return `data:image/png;base64,${this.previewSelectedLogo}`
        },
        setObjectToBePatched() {
            const patchProperties = ['websiteUrl', 'termsAndConditionsUrl', 'logo', 'introduction', 'isVatExempt']
            const array = []

            patchProperties.forEach((property) => {
                if (this.provider[property] !== this.originalProvider[property]) {
                    return array.push({
                        value: this.provider[property],
                        path: property,
                        op: 'replace'
                    })
                }
            })

            return array
        },
    },
    methods: {
        ...mapActions('providerModule', ['patchProvider', 'patchProviderOleType']),
        ...mapMutations('providerModule', [
            'setProviderLogo',
            'setWebsiteUrl',
            'setTermsAndConditionsUrl',
            'setProviderIntroduction',
            'setProviderIsVatExempt',
            'setOleTypeBaseUrl',
            'setOleTypeHasSso',
            'setOriginalProvider'
        ]),

        triggerChangeLogo(logo) {
            if (logo && logo[0]) {
                this.previewSelectedLogo = logo[0].base64FileString
            } else {
                this.previewSelectedLogo = null
            }

            this.setProviderLogo(logo)
        },

        setProviderOleTypesToPatch(oleType) {
            const patchProperties = ['baseUrl', 'hasSso']
            const array = []

            const current = this.provider.providerOleTypes.find((currentItem) => currentItem.id === oleType.id)

            patchProperties.forEach((property) => {
                if (oleType[property] !== current[property]) {
                    return array.push({
                        value: current[property],
                        path: property,
                        op: 'replace'
                    })
                }
            })

            return array
        },

        async updateProvider() {
            try {
                if (this.provider.id && this.setObjectToBePatched.length > 0) {
                    await this.patchProvider({
                        providerId: this.provider.id,
                        objectToBePatched: this.setObjectToBePatched
                    });
                }

                if (this.originalProvider.providerOleTypes && this.originalProvider.providerOleTypes.length > 0) {
                    await this.originalProvider.providerOleTypes.forEach(async (oleType) => {
                        const filteredProperties = this.setProviderOleTypesToPatch(oleType)

                        if (filteredProperties.length > 0) {
                            await this.patchProviderOleType({
                                providerOleTypeId: oleType.id,
                                providerOleTypes: filteredProperties
                            })
                        }
                    })
                }

                this.setOriginalProvider(this.provider);
            } catch (error) {
                if (error && error.description == "not_supported") {
                    this.$refs.uploadFile.selectedFiles = [];
                    this.previewSelectedLogo = null;
                    this.provider.logoUrl = this.originalProvider.logoUrl;
                }
            }
        }

    }
}
</script>